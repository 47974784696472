import React from 'react'

interface IProps {
    customclass: string;
}

const Spacer: React.FC <IProps> = ({customclass}) => {
  return (
    <div className={customclass}></div>
  )
}

export default Spacer