import React from 'react';
import TelegramIcon from './TelegramIcon';

const Footer = () => {
  const TOKEN_ADDRESS = 'EQCOKRrOezeZfCgkKHO-PBnheexXjrFHZS-eRO5D_8h5YM5N';
  const TOKEN_ADDRESS_SHORT =
    TOKEN_ADDRESS.slice(0, 6) + '...' + TOKEN_ADDRESS.slice(-6);

  return (
    <footer className="py-5 lg:py-10">
      <div className="flex flex-col gap-2 lg:gap-4">
        <div className="flex flex-col gap-2.5 lg:flex-row lg:justify-between lg:gap-4">
          <a
            href="https://t.me/tolmeme"
            target="_blank"
            className="flex cursor-pointer items-center gap-2 underline-offset-2 hover:underline"
            rel="noreferrer"
          >
            <TelegramIcon fill="#e5e7eb" className="size-4" />
            <span>Telegram</span>
          </a>
          <a
            className="cursor-pointer underline-offset-2 hover:underline"
            href="https://www.geckoterminal.com/ton/pools/EQCBwglxhJgTue5tMPX4KE0O1it4dNjD_f53WM8asjgMiSYx"
            target="_blank"
            rel="noreferrer"
          >
            GeckoTerminal
          </a>
          <a
            className="cursor-pointer underline-offset-2 hover:underline"
            href="https://www.coingecko.com/en/coins/the-open-league-meme"
            target="_blank"
            rel="noreferrer"
          >
            CoinGecko
          </a>
          <a
            className="hidden cursor-pointer underline-offset-2 hover:underline lg:block"
            href="https://tonviewer.com/EQCOKRrOezeZfCgkKHO-PBnheexXjrFHZS-eRO5D_8h5YM5N"
            target="_blank"
            rel="noreferrer"
          >
            Token address: {TOKEN_ADDRESS}
          </a>
          <a
            className="cursor-pointer underline-offset-2 hover:underline lg:hidden"
            href="https://tonviewer.com/EQCOKRrOezeZfCgkKHO-PBnheexXjrFHZS-eRO5D_8h5YM5N"
            target="_blank"
            rel="noreferrer"
          >
            Token address: {TOKEN_ADDRESS_SHORT}
          </a>
        </div>
        {/* <!-- 
      <a
        className="cursor-pointer underline-offset-2 hover:underline lg:hidden"
        href="https://www.geckoterminal.com/ton/pools/EQCBwglxhJgTue5tMPX4KE0O1it4dNjD_f53WM8asjgMiSYx"
        target="_blank">GeckoTerminal</a
      > --> */}

        <p className="text-xs text-gray-400 lg:text-sm">
          Disclaimer: TOL ($TOL) is a meme community token inspired by The Open
          League and the TON ecosystem. TOL is not an official token of The Open
          League. TOL is created for fun and entertainment purposes, and it
          encourages users and contributors to learn about the TON ecosystem,
          explore its projects, and potentially earn rewards. TOL does not
          represent any official affiliation with The Open League. Users should
          participate in TOL activities with a spirit of humor and understanding
          that TOL is a community-driven initiative.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
