import QRCodeStyling from 'qr-code-styling';
import { useLayoutEffect, useRef } from 'react';

const useQRCode = (link: string, imageUrl?: string) => {
  const qrCodeRef = useRef<HTMLDivElement>(null);

  // TODO: fix eslint error
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const qr = new QRCodeStyling({
    width: 200,
    height: 200,
    type: 'svg',
    data: link,
    margin: 10,
    qrOptions: { typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'Q' },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 4 },
    dotsOptions: { type: 'rounded', color: '#000000' },
    backgroundOptions: { color: '' },
    image: imageUrl,
    cornersSquareOptions: { type: 'extra-rounded', color: '#000000' },
    cornersDotOptions: { type: undefined, color: '#000000' },
  });

  useLayoutEffect(() => {
    const qrCurrent = qrCodeRef.current;

    if (!qrCurrent) {
      return;
    }

    qr.append(qrCodeRef.current);

    return () => {
      qrCurrent.innerHTML = '';
    };
  }, [link, qr]);

  return qrCodeRef;
};

export const QRCode = (props: { link: string; imageUrl?: string }) => {
  const { link, imageUrl } = props;
  const qrCodeRef = useQRCode(link, imageUrl);

  return <div ref={qrCodeRef} />;
};
