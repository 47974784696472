import { toUserFriendlyAddress } from '@tonconnect/ui';

export const reaction = async (
  dir: string,
  link: string,
  walletAddress?: string
) => {
  if (!walletAddress) {
    return null;
  }

  const meme_id = link.split('/').pop();

  await fetch(
    `${process.env.REACT_APP_API_URL}${
      dir === 'right' ? '/like/' : '/dislike/'
    }${meme_id}`,
    {
      method: 'POST',
      headers: {
        'x-wallet-address': toUserFriendlyAddress(walletAddress),
      },
    }
  );
};

export const getMemes = async (walletAddress?: string) => {
  if (!walletAddress) {
    return null;
  }

  const res = await fetch(`${process.env.REACT_APP_API_URL}/memes/5`, {
    headers: {
      'x-wallet-address': toUserFriendlyAddress(walletAddress),
    },
  });

  const data = await res.json();

  return data.ids;
};
