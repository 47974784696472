import { toUserFriendlyAddress } from '@tonconnect/ui';
import { useMemo } from 'react';
import { useTonConnectUI } from '../hooks/ton-connect';
import { useConnectionState } from '../hooks/ton-connect';

export const ConnectWallet = ({ onConnect }: { onConnect?: () => void }) => {
  const state = useConnectionState();
  const tonConnectUI = useTonConnectUI();

  const shortAddress = useMemo(() => {
    if (!tonConnectUI?.wallet) return null;

    return toUserFriendlyAddress(tonConnectUI?.wallet.account.address);
  }, [tonConnectUI?.wallet]);

  return (
    <div id="tonconnect__root">
      <button
        type="button"
        className={
          'bg-main-blue-700 h-12 rounded-full px-4 text-xl font-bold lg:h-16 lg:px-6 whitespace-nowrap'
        }
        onClick={() => state === 'disconnected' && tonConnectUI?.openModal()}
      >
        {state === 'loading' && 'loading...'}
        {state === 'connected' && shortAddress}
        {state === 'disconnected' && 'Connect Wallet'}
      </button>
    </div>
  );
};
