import { Link as ReactLink } from 'react-router-dom';

export const Link = (
  props: React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    to: string;
  }
) => {
  return (
    // @ts-expect-error react-router types
    <ReactLink
      {...props}
      className="rounded-full bg-main-blue-700 px-3 py-1 lg:text-2xl font-semibold grid place-content-center lg:h-16 lg:px-6"
    />
  );
};
