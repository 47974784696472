import { useStore } from "@nanostores/react";
import { TonConnectUI, toUserFriendlyAddress } from "@tonconnect/ui";
import { atom } from "nanostores";
import { useEffect, useMemo } from "react";

const $tonConnectUI = atom<TonConnectUI>(
  new TonConnectUI({
    manifestUrl:
      "https://gist.githubusercontent.com/manylovv/ead376d0e593fd3a0d2bb7dd760ab0cb/raw",
  }),
);

type ConnectionState = "connected" | "disconnected" | "loading";

const $connectionState = atom<ConnectionState>("loading");

export const useTonConnectUI = () => {
  const tonConnectUI = useStore($tonConnectUI);

  return tonConnectUI;
};

export const useConnectionState = () => {
  const tonConnectUI = useTonConnectUI();

  useEffect(() => {
    const waitForConnection = async () => {
      await tonConnectUI.connectionRestored;
      if (tonConnectUI.wallet) {
        $connectionState.set("connected");
      }

      if (!tonConnectUI.wallet) {
        $connectionState.set("disconnected");
      }
    };

    waitForConnection();
  }, [tonConnectUI]);

  tonConnectUI.onStatusChange((wallet) => {
    if (wallet) {
      $connectionState.set("connected");
    }

    if (!wallet) {
      $connectionState.set("disconnected");
    }
  });

  return useStore($connectionState);
};

export const $walletAddress = atom<string | null>(null);

export const useWalletAddress = (short: boolean = false) => {
  const tonConnectUI = useTonConnectUI();

  const address = useMemo(() => {
    const address = tonConnectUI.wallet?.account.address;

    if (!address) {
      return null;
    }

    const userFriendly = toUserFriendlyAddress(address);

    if (short) {
      return userFriendly.slice(0, 6) + "..." + userFriendly.slice(-6);
    }

    return userFriendly;
  }, [tonConnectUI.wallet]);

  $walletAddress.set(address);

  return address;
};
