import React, { useEffect, useMemo, useRef, useState } from 'react';
import TinderCard from 'react-tinder-card';
import { ConnectWallet } from '../../components/ConnectButton';
import { DisconnectButton } from '../../components/DisconnectButton';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { ChevronLeft } from '../../components/ChevronLeft';
import { ChevronRight } from '../../components/ChevronRight';
import { Link } from '../../components/Link';
import { QRCode } from '../../components/QrCode';
import { useConnectionState, useTonConnectUI } from '../../hooks/ton-connect';
import { useIsMobile } from '../../hooks/useIsMobile';
import Container from '../../layouts/Container';
import Layout from '../../layouts/default';
import { getMemes, reaction } from '../../state/memetinder';
import { cn } from '../../utils';
import './style.css';

function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const MemeTinderPage: React.FC = () => {
  const state = useConnectionState();
  const tonConnectUI = useTonConnectUI();
  const [removedImages, setRemovedImages] = useState<string[]>([]);
  const [stackMemes, setStack] = useState<string[]>([]);
  const [memesRotations, setMemesRotations] = useState<number[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const currentIndexRef = useRef(currentIndex);
  const walletAddress = tonConnectUI?.wallet?.account.address;
  const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
  useEffect(() => {
    const rotations = Array(stackMemes.length)
      .fill(0)
      .map(() => getRandomNumber(-4, 4));

    setMemesRotations(rotations);

    console.log('rotations', rotations);
  }, [stackMemes]);

  const childRefs = useMemo(
    () =>
      Array(stackMemes.length)
        .fill(0)
        .map(() => React.createRef()),
    [stackMemes.length]
  );

  const updateCurrentIndex = (val: any) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const swipe = async (dir: string) => {
    if (currentIndex <= stackMemes.length - 1) {
      //@ts-ignore
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  const swiped = (dir: string, img: string, idx: number) => {
    updateCurrentIndex(currentIndex - 1);
    console.log(img, 'img', dir, 'dir');
    reaction(dir, img, walletAddress);
    stackMemes.pop();
  };

  const isWebApp = false;

  const onCardLeftScreen = (myIdentifier: string) => {
    setRemovedImages((prev) => [...prev, myIdentifier]);
  };

  const refetchMemes = async () => {
    const memes = await getMemes(walletAddress);

    if (!memes) {
      return;
    }

    if (stackMemes.length === 4) {
      setStack([
        ...memes.map((value: string) => `${BUCKET_URL}/${value}`),
        ...stackMemes,
      ]);
    }

    if (stackMemes.length === 0 || currentIndex < 0) {
      updateCurrentIndex(memes.length - 1);
      setStack([...memes.map((value: string) => `${BUCKET_URL}/${value}`)]);
    }
  };

  useEffect(() => {
    refetchMemes();
  }, [state]);

  useEffect(() => {
    if (stackMemes.length <= 4) {
      refetchMemes();
    }
  }, [stackMemes.length]);

  useEffect(() => {
    if (currentIndex < stackMemes.length) {
      updateCurrentIndex(stackMemes.length - 1);
    }
  }, [stackMemes]);

  const isMobile = useIsMobile();

  if (!isMobile && !process.env.DEV) {
    return (
      <div className="h-screen flex flex-col justify-center items-center bg-[#0A131F] text-white antialiased gap-6">
        <p className="whitespace-pre-line text-center text-2xl leading-none">
          Play on your mobile!
        </p>
        <div className="p-2 rounded-3xl bg-white">
          <QRCode link="https://t.me/@tolmeme_bot" imageUrl="/telegram.svg" />
        </div>
        <p className="text-sm text-gray-500 leading-none">$TOL Meme2Earn</p>
      </div>
    );
  }

  return (
    <Layout>
      <div className="relative flex min-h-screen flex-col overflow-hidden">
        <Container>
          <Header allowLinkToHome={!isWebApp}>
            <DisconnectButton />

            <Link to="/meme2earn">Earn</Link>
          </Header>
        </Container>

        <Container classNames="flex-1">
          <div className="flex flex-col gap-2.5 items-center justify-center h-[calc(100vh-100px)]">
            <div className="flex items-center justify-center flex-col">
              <div
                className={
                  state !== 'connected'
                    ? 'hidden'
                    : 'h-[380px] w-[280px] flex items-center justify-center'
                }
              >
                {stackMemes.map((img, idx) => (
                  <TinderCard
                    //@ts-ignore
                    ref={childRefs[idx]}
                    className={cn(
                      'rounded-2xl w-[280px] shadow-2xl shadow-black/50 drop-shadow-xl p-2 bg-gray-800 flex items-center justify-center overflow-hidden absolute',
                      `rotation-${memesRotations[idx]}`
                    )}
                    onSwipe={(dir) => swiped(dir, img, idx)}
                    onCardLeftScreen={(dir) => {
                      onCardLeftScreen(dir);
                    }}
                    key={idx}
                  >
                    <img className="rounded-xl" src={img} alt="meme" />
                  </TinderCard>
                ))}
              </div>
            </div>
            <div className="align-center flex justify-center gap-4 px-4 py-12 items-center w-full">
              {state === 'connected' ? (
                <div className="flex flex-col items-center gap-4 w-full">
                  <div className="flex items-center gap-4 w-full">
                    <button
                      className="bg-gradient-to-b from-rose-600/90 to-rose-600/60 shadow-lg py-4 text-3xl flex-1 rounded-xl shadow-rose-600/20 relative"
                      onClick={() => swipe('left')}
                    >
                      <ChevronLeft className="absolute left-2 top-1/2 -translate-y-1/2 size-4 text-rose-100" />
                      <div>👎</div>
                    </button>
                    <button
                      className="bg-gradient-to-b from-[#16c783df] to-[#16c7837e] shadow-lg shadow-[#16c7832b] py-4 text-3xl flex-1 rounded-xl relative text-emerald-100"
                      onClick={() => swipe('right')}
                    >
                      <ChevronRight className="absolute right-2 top-1/2 -translate-y-1/2 size-4" />
                      <span>👍</span>
                    </button>
                  </div>

                  <p className="text-gray-400">
                    or you can swipe meme to react
                  </p>
                </div>
              ) : (
                <div className="-translate-y-10 flex items-center gap-4 flex-col  text-center sm:text-left sm:flex-row">
                  <span className="text-base max-w-32">
                    You can’t like memes without wallet
                  </span>
                  <ConnectWallet />
                </div>
              )}
            </div>
          </div>
          <Container>
            <Footer />
          </Container>
        </Container>
      </div>
    </Layout>
  );
};

export default MemeTinderPage;
