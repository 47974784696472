import '@fontsource/single-day';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import { HomePage } from './pages/Home';
import { Meme2Earn } from './pages/Meme2Earn';
import LeaderBoardPage from './pages/Meme2Earn/LeaderBoardPage';
import MemeTinderPage from './pages/MemeTinder';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/meme2earn" exact component={Meme2Earn} />
        <Route
          path="/meme2earn/leaderboard"
          exact
          component={LeaderBoardPage}
        />
        <Route path="/memetinder" exact component={MemeTinderPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
