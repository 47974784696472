import React from "react";
import logo from "../assets/logo.png";

interface IProps {
  children: React.ReactNode;
  allowLinkToHome?: boolean;
}

const Header: React.FC<IProps> = ({ children, allowLinkToHome }) => {
  return (
    <header className="relative z-10 flex h-[var(--header-height-mobile)] items-center justify-between md:h-28 lg:h-[var(--header-height-desktop)]">
      {allowLinkToHome ? (
        <a href="/" astro-prefetch="load" className="flex items-center">
          <img src={logo} alt="TOL logo" className="size-12" />
          <span className="text-2xl font-bold">$TOL</span>
        </a>
      ) : (
        <span className="flex items-center">
          <img src={logo} alt="TOL logo" className="size-12" />
          <span className="text-2xl font-bold">$TOL</span>
        </span>
      )}

      <div className="flex gap-2">{children}</div>
    </header>
  );
};

export default Header;
