import { useEffect, useState } from "react";
import { ConnectWallet } from "../ConnectButton";
import { useConnectionState, useWalletAddress } from "../../hooks/ton-connect";
import { SelectAndUpload } from "./SelectAndUpload";
import { useClaimInfo } from "../../hooks/meme2earn";
import {
  getSecondsLeft,
  claim,
  reset,
  uploadFile,
} from "../../state/meme2earn";
import { toast } from "react-hot-toast";
import { SWRConfig, useSWRConfig } from "swr";
import { Spinner } from "./Spinner";
import { Counter } from "./Counter";
import { Claim } from "./Claim";

export const UploadMeme = () => {
  return (
    <SWRConfig>
      <UploadMemeInternal />
    </SWRConfig>
  );
};

const UploadMemeInternal = () => {
  const connectionState = useConnectionState();
  const { claimInfo, isLoading } = useClaimInfo();
  const [hasTimePassed, setHasTimePassed] = useState(false);
  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (claimInfo?.uploadedAt) {
      const secondsLeft = getSecondsLeft(claimInfo.uploadedAt);

      console.log("SECONDS LEFT in UploadMeme", secondsLeft);

      if (secondsLeft <= 0) {
        setHasTimePassed(true);
      }
    }
  }, [claimInfo]);

  console.log("-- has time passed --", hasTimePassed);
  console.log("-- uploaded at --", claimInfo?.uploadedAt);
  console.log("-- reaction count --", claimInfo?.reactionCount);

  const handleUpload = async (file: File) => {
    const newData = {
      reward: 0,
      reactionCount: 0,
      uploadedAt: Date.now(),
    };

    console.log("-- new data on handle upload --", newData);

    await mutate("/claimInfo", newData, {
      optimisticData: newData,
    });

    const { uploadedAt } = await uploadFile(file);

    await mutate(
      "/claimInfo",
      {
        ...newData,
        uploadedAt,
      },
      {
        optimisticData: {
          ...newData,
          uploadedAt,
        },
      }
    );
  };

  const handleReset = async () => {
    await mutate("/claimInfo", null, {
      optimisticData: null,
      revalidate: false,
    });
    await reset();
    setHasTimePassed(false);
  };

  const handleClaim = async (reward: number) => {
    await claim();
    toast.success(<SuccessToast amount={reward} />);
    await mutate("/claimInfo", null, {
      optimisticData: null,
      revalidate: false,
    });
    setHasTimePassed(false);
  };

  const handleTimePassed = async () => {
    await mutate("/claimInfo");
    setHasTimePassed(true);
  };

  if (isLoading || connectionState === "loading") {
    return <Spinner />;
  }

  if (connectionState !== "connected") {
    return <ConnectWallet />;
  }

  if (!claimInfo) {
    return <SelectAndUpload onUpload={handleUpload} />;
  }

  // if (!hasTimePassed) {
  //   return <Counter onTimePassed={handleTimePassed} claimInfo={claimInfo} />;
  // }

  return (
    <Claim
      onClaim={handleClaim}
      onReset={handleReset}
      claimInfo={claimInfo}
      hasReactions={claimInfo.reactionCount > 0}
    />
  );
};

const SuccessToast = (props: { amount: number }) => {
  const walletAddress = useWalletAddress();
  return (
    <div>
      You will recieve <b>{props.amount} $TOL</b> in a few minutes!
      <br />
      Check your transactions on{" "}
      <a
        href={`https://tonviewer.com/${walletAddress}`}
        className="underline underline-offset-2"
        target="_blank"
      >
        tonviewer.com
      </a>
    </div>
  );
};
