import { useWalletAddress } from "./ton-connect";
import { fetcher } from "../state/meme2earn";
import useSWR from "swr";

export type ClaimInfo = {
  uploadedAt: number;
  reactionCount: number;
  messageId: string;
  reward: number;
};

export const useClaimInfo = () => {
  const walletAddress = useWalletAddress();
  const { data, error, isLoading, mutate, isValidating } =
    useSWR<ClaimInfo | null>(walletAddress ? `/claimInfo` : null, fetcher, {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    });

  return { claimInfo: data, error, isLoading, mutate, isValidating };
};
