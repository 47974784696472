import { useConnectionState, useTonConnectUI } from '../hooks/ton-connect';

export const DisconnectButton = ({ onConnect }: { onConnect?: () => void }) => {
  const state = useConnectionState();
  const tonConnectUI = useTonConnectUI();

  if (state !== 'connected') {
    return null;
  }

  return (
    <button
      type="button"
      className={
        'rounded-full bg-gray-700/50 px-3 py-1 transition duration-100 hover:bg-gray-700/30 hover:text-gray-300'
      }
      onClick={() => tonConnectUI.disconnect()}
    >
      {state === 'connected' && 'Disconnect'}
    </button>
  );
};
