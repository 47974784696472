import { useClaimInfo, type ClaimInfo } from "../../hooks/meme2earn";
import { RefetchIcon } from "./RefetchIcon";
import { cn } from "../../utils";

export const Claim = ({
  hasReactions,
  claimInfo,
  onClaim,
  onReset,
}: {
  hasReactions: boolean;
  claimInfo: ClaimInfo;
  onClaim: (reward: number) => void;
  onReset: () => void;
}) => {
  const { mutate, isValidating } = useClaimInfo();

  return (
    <div>
      <div className="flex flex-col items-center gap-3 text-2xl">
        <div className="flex items-center gap-3">
          {claimInfo.reactionCount > 0 && (
            <p>Congrats! You've got {claimInfo.reactionCount} reactions</p>
          )}
          {claimInfo.reactionCount === 0 && (
            <p>You've got no reactions sadly</p>
          )}
          <button onClick={() => mutate()}>
            <RefetchIcon className={cn(isValidating && "animate-spin")} />
          </button>
        </div>
        <button
          className="rounded-full bg-main-blue-700/40 px-4 py-1  text-main-blue-700 shadow-sm transition duration-100 hover:bg-main-blue-700/80 hover:text-white"
          onClick={onReset}
        >
          Create new meme
        </button>
      </div>
    </div>
  );

  if (!hasReactions) {
    return (
      <div className="flex flex-col items-center gap-3 text-2xl">
        <div className="flex items-center gap-3">
          <p>You've got no reactions sadly</p>
          <button onClick={() => mutate()}>
            <RefetchIcon className={cn(isValidating && "animate-spin")} />
          </button>
        </div>
        <button
          className="rounded-full bg-main-blue-700/40 px-4 py-1  text-main-blue-700 shadow-sm transition duration-100 hover:bg-main-blue-700/80 hover:text-white"
          onClick={onReset}
        >
          Create new meme
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center gap-3 text-2xl">
      <div className="flex items-center gap-3">
        <p>Congrats! You've got {claimInfo.reactionCount} reactions</p>
        <button onClick={() => mutate()}>
          <RefetchIcon className={cn(isValidating && "animate-spin")} />
        </button>
      </div>
      <button
        className="rounded-full bg-main-blue-700 px-4 py-2  text-white shadow-sm transition duration-100 hover:opacity-80"
        onClick={() => onClaim(claimInfo.reward)}
        disabled={true}
      >
        Claim {claimInfo.reward} $TOL
      </button>
    </div>
  );
};
