import { useLeaderboard } from '../hooks/leaderboard';
import { useWalletAddress } from '../hooks/ton-connect';
import { cn } from '../utils';
import { isAddressesEqual } from '../utils/ton-address';
import gold from '../assets/trophy/gold.png';
import silver from '../assets/trophy/silver.png';
import bronze from '../assets/trophy/bronze.png';

export const Leaderboard = () => {
  const { leaderboard, isLoading } = useLeaderboard();
  const userAddress = useWalletAddress();
  const formatAddress = (address: string) => {
    return address.slice(0, 6) + '...' + address.slice(-6);
  };

  return (
    <div className="flex h-fit flex-col gap-2">
      {isLoading && <LeaderboardSkeleton />}
      {leaderboard && leaderboard.length > 0 && (
        <>
          <div className="flex items-baseline justify-between pl-3 pr-4 text-gray-300/80 sm:pl-6 sm:pr-6">
            <div className="flex items-center gap-3">
              <div className="sm:hidden">#</div>
              <div>Address</div>
            </div>
            <div>$TOL earned</div>
          </div>
          <div className="flex flex-col gap-2">
            {leaderboard
              .slice()
              .sort((a, b) => b.score - a.score)
              .slice(0, 50)
              .map((user, i) => (
                <div className="relative" key={user.address}>
                  <div className="absolute -left-1 top-1/2 -translate-y-1/2 sm:-left-10">
                    <div className="hidden sm:block">
                      {i === 0 && <img src={gold.src} className="size-6" />}
                      {i === 1 && <img src={silver.src} className="size-6" />}
                      {i === 2 && <img src={bronze.src} className="size-6" />}
                    </div>

                    {i >= 3 && (
                      <div className={cn('hidden w-6 justify-center sm:flex')}>
                        {i + 1}
                      </div>
                    )}
                  </div>
                  <a
                    className={cn(
                      'flex h-[66px] cursor-pointer items-center justify-between rounded-xl border border-gray-700/60  bg-gray-950/20 px-6 pl-4 shadow-sm backdrop-blur-sm transition-all hover:scale-[1.005] hover:bg-gray-950/30 sm:pl-6',
                      isAddressesEqual(user.address, userAddress) &&
                        'border-main-blue-500/50 bg-main-blue-700/10 text-white hover:bg-main-blue-900/10'
                    )}
                    key={user.address}
                    href={`https://tonviewer.com/${user.address}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex items-center gap-3">
                      <div className="flex items-center gap-4">
                        <div className="text-sm text-gray-300 sm:hidden">
                          {i + 1}
                        </div>
                        <div className="hidden text-xl sm:block">
                          {user.address}
                        </div>
                        <div className="text-2xl sm:hidden">
                          {formatAddress(user.address)}
                        </div>
                      </div>

                      {isAddressesEqual(user.address, userAddress) && (
                        <div className="rounded-full bg-main-blue-700/30 px-2 py-0.5 text-sm text-main-blue-500">
                          You
                        </div>
                      )}
                    </div>

                    <div className="text-2xl">{user.score}</div>
                  </a>
                </div>
              ))}
          </div>
        </>
      )}
      {leaderboard && leaderboard.length === 0 && (
        <div className="flex items-center justify-center">
          <p className="text-lg">No one has submitted a meme yet</p>
        </div>
      )}
    </div>
  );
};

const LeaderboardSkeleton = () => {
  return (
    <div className="flex flex-col gap-2">
      {[...Array(10)].map((_, i) => (
        <div
          key={i}
          className="flex h-[66px] cursor-pointer items-center justify-between rounded-xl border border-gray-700/60 bg-gray-950/20 px-6 shadow-sm backdrop-blur-sm"
        >
          <div className="h-5 w-40 animate-pulse  rounded-full bg-gray-600/30 sm:w-[430px]" />
          <div className="h-5 w-[100px] rounded-full bg-gray-600/30" />
        </div>
      ))}
    </div>
  );
};
