import { Address } from "./ton/Address";

export const isAddressesEqual = (
  address1?: string | null,
  address2?: string | null,
) => {
  if (!address1 || !address2) {
    return false;
  }

  const parsedAddress1 = Address.parseFriendly(address1).address.toString({
    bounceable: true,
  });
  const parsedAddress2 = Address.parseFriendly(address2).address.toString({
    bounceable: true,
  });

  return parsedAddress1 === parsedAddress2;
};
