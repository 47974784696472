import React from "react";
import donut from "../../assets/donut.png";
import Spacer from "../Spacer";
import style from "./HeroImage.module.css"
import logo from "../../assets/logo.png";

const HeroImage: React.FC = () => {
  return (
    <>
      <div className={style.gradient}></div>
      <Spacer customclass="h-4 md:h-2" />
      <div className="relative -z-10 mx-auto flex h-fit w-fit items-center justify-center">
        <div className="flex select-none flex-nowrap font-wosker text-[120px] leading-none text-[#047CFA] md:text-[200px] lg:text-[400px]">
          <span>T</span>
          <img
            className="relative -mr-1 inline aspect-square w-[116px] flex-none shrink-0 -translate-y-1 object-scale-down md:!-mr-1 md:w-[190px] lg:-right-1 lg:w-[365px] lg:-translate-y-3"
            src={donut}
            alt={"Donut (O)"}
          />
          <span>L</span>
        </div>

        <img
          src="/memes/nyan-cat.gif"
          className="absolute inset-0 w-16 -translate-y-[12px] translate-x-[12px] md:w-20 md:-translate-y-[8px] md:translate-x-[38px] lg:w-32 lg:-translate-y-[5px] lg:translate-x-[90px]"
        />

        <img
          src="/memes/doge-2.png"
          className="absolute inset-0 size-16 translate-x-[252px] translate-y-[21px] scale-x-[-1] md:size-24 md:translate-x-[430px] md:translate-y-[44px] lg:size-32 lg:translate-x-[878px] lg:translate-y-[130px]"
        />

        <img
          src="/memes/pepe-1.png"
          className="absolute inset-0 size-8 translate-x-[0.5px] translate-y-[15px] md:size-12 md:translate-x-[1px] md:translate-y-[30.5px] lg:size-[80px] lg:translate-x-[2px] lg:translate-y-[77.5px]"
        />

        <img
          src="/memes/mike-1.png"
          className="absolute inset-0 size-10 -translate-y-[25px] translate-x-[212px] md:size-16 md:-translate-y-[38px] md:translate-x-[355px] lg:size-24 lg:-translate-y-[46px] lg:translate-x-[714px]"
        />

        <img
          src="/memes/dank-1.png"
          className="absolute inset-0 size-8 translate-x-[37px] translate-y-[67px] md:size-16 md:translate-x-[50px] md:translate-y-[100.5px] lg:size-24 lg:translate-x-[132px] lg:translate-y-[233px]"
        />

        <img
          src="/memes/popcat-2.png"
          className="absolute inset-0 hidden size-24 lg:block lg:translate-x-[740px] lg:translate-y-[233px]"
        />
      </div>
    </>
  );
};

export default HeroImage;
