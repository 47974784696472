import { useEffect } from 'react';
import logo from '../../assets/logo.png';
import { DisconnectButton } from '../../components/DisconnectButton';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Link } from '../../components/Link';
import { UploadMeme } from '../../components/Meme2earn/Meme2Eearn';
import Spacer from '../../components/Spacer';
import Container from '../../layouts/Container';
import Layout from '../../layouts/default';
import style from './Meme2Earn.module.css';

interface IProps {
  isWebApp?: boolean;
}

export const Meme2Earn: React.FC<IProps> = ({ isWebApp = false }) => {
  useEffect(() => {
    const chevronInfoDown = document.getElementById('chevron-info-down');
    // if someone scrolls down, hide the chevron
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        chevronInfoDown?.classList.add('opacity-0');
        chevronInfoDown?.classList.remove('opacity-100');
      } else {
        chevronInfoDown?.classList.add('opacity-100');
        chevronInfoDown?.classList.remove('opacity-0');
      }
    });

    // on click scroll down 20px

    chevronInfoDown?.addEventListener('click', () => {
      window.scrollTo({
        top: window.scrollY + 200,
        behavior: 'smooth',
      });
    });
  });

  return (
    <Layout>
      <div className="relative flex h-screen flex-col">
        <Container>
          <Header allowLinkToHome={!isWebApp}>
            {/* @ts-ignore */}
            <Link
              to={
                isWebApp
                  ? '/webapp/meme2earn/leaderboard'
                  : '/meme2earn/leaderboard'
              }
            >
              Leaderboard
            </Link>

            {/* @ts-ignore */}
            <Link to="/memetinder">
              <div className="sm:block hidden">MemeTinder</div>
              <div className="sm:hidden">Tinder</div>
            </Link>
          </Header>
        </Container>

        <Container classNames="flex-1">
          <div className="grid h-full place-content-center lg:-translate-y-[5%]">
            <div className="space-y-7">
              <div
                className={`${style.gradient} "relative flex flex-col items-center justify-center gap-4 text-center lg:gap-4"`}
              >
                <h1 className="text-[56px] font-bold leading-none text-white md:text-[120px]">
                  Create Memes
                  <br />
                  Earn $TOL
                </h1>
              </div>

              <div className="flex min-h-16 items-center justify-center md:min-h-[130px]">
                <UploadMeme />
              </div>

              <div className="flex flex-col items-center gap-2">
                <div
                  aria-label="reactions-to-tol-image"
                  className="flex items-center justify-center md:gap-8"
                >
                  <div className="flex gap-2">
                    <img
                      src="/reactions/heart.png"
                      className="relative bottom-0 inline-block h-6 flex-1 rounded-full object-cover md:h-8"
                    />
                    <img
                      src="/reactions/stone.png"
                      className="relative bottom-0 inline-block h-6 flex-1 rounded-full object-cover md:h-8"
                    />
                    <img
                      src="/reactions/fire.png"
                      className="relative bottom-0 inline-block h-6 flex-1 rounded-full object-cover md:h-8"
                    />
                  </div>

                  <div className="hidden items-center gap-3 md:flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                      ></path>
                    </svg>

                    <div className="flex translate-x-2 items-center">
                      <img
                        src={logo}
                        alt="TOL logo"
                        className="size-8 md:size-10"
                      />
                      <span className="text-xl font-bold md:text-2xl">
                        $TOL
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex h-fit w-full flex-col items-center justify-center gap-1 sm:flex-row sm:gap-40">
                  <p className="text-lg">1 Reaction = 25 $TOL</p>
                  <DisconnectButton />
                </div>
              </div>
            </div>
          </div>
        </Container>

        {!isWebApp && (
          <button
            id="chevron-info-down"
            className="absolute bottom-10 left-1/2 flex -translate-x-1/2 items-center justify-center transition-opacity"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </button>
        )}
      </div>

      {!isWebApp && (
        <div>
          <Container>
            <div className="mx-auto flex max-w-xl flex-col gap-4">
              <div className="flex flex-col gap-2 p-2">
                <h2 className="text-2xl font-bold lg:text-center lg:text-4xl">
                  How it works
                </h2>

                <ul className="inline-block max-w-xl text-xl *:text-pretty lg:text-center">
                  <li>1. Create a meme about The Open League</li>
                  <li>2. Connect your wallet and submit the meme</li>
                  <li>
                    3. Claim your $TOL depending on how many reactions you've
                    got!
                  </li>
                </ul>
              </div>
            </div>
          </Container>
          <Spacer customclass="h-10" />
          <Container>
            <Footer />
          </Container>
        </div>
      )}
    </Layout>
  );
};
