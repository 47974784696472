import React from 'react';
import { cn } from '../utils';
import { Link } from 'react-router-dom';

interface IProps {
  children: any;
  type: 'button' | 'link';
  disabled?: boolean;
  href?: string;
  customclass?: string;
}

const Button: React.FC<IProps> = ({
  children,
  type,
  disabled,
  href,
  customclass,
}) => {
  const classNames =
    'flex justify-center items-center text-xl lg:text-2xl font-semibold rounded-full bg-[#047CFA] transition duration-100 hover:opacity-80 h-12 lg:h-16 px-4 lg:px-6 disabled:opacity-60 disabled:pointer-events-none';

  return (
    <>
      {type === 'button' ? (
        <>
          <button className={cn(classNames, customclass)}>{children}</button>
        </>
      ) : (
        <>
          <Link
            to={href!}
            className={cn(
              classNames,
              customclass,
              disabled && 'pointer-events-none opacity-60'
            )}
          >
            {children}
          </Link>
        </>
      )}
    </>
  );
};

export default Button;
