import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Leaderboard } from '../../components/Leaderboard';
import { Link } from '../../components/Link';
import Container from '../../layouts/Container';
import Layout from '../../layouts/default';

const LeaderBoardPage: React.FC = () => {
  const isWebApp = false;
  return (
    <Layout>
      <div className="flex min-h-screen w-full flex-col">
        <Container>
          <Header allowLinkToHome={!isWebApp}>
            <Link to={'/meme2earn'}>Meme2Earn</Link>
          </Header>
        </Container>

        <Container classNames="!max-w-3xl flex-1">
          <div className="flex flex-col items-center gap-5 sm:gap-8">
            <h1 className="text-4xl font-bold sm:text-5xl">Leaderboard</h1>
            <div className={'w-full'}>
              <Leaderboard />
            </div>
          </div>
        </Container>
      </div>

      {!isWebApp && (
        <Container>
          <Footer />
        </Container>
      )}
    </Layout>
  );
};

export default LeaderBoardPage;
