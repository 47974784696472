import useSWR from "swr";
import { API_URL } from "../state/meme2earn";

const fetcher = async (url: string) => {
  const res = await fetch(`${API_URL}${url}`);
  const data = await res.json();
  return data;
};

export type Leaderboard = {
  address: string;
  score: number;
}[];

export const useLeaderboard = () => {
  const { data: leaderboard, isLoading } = useSWR<Leaderboard>(
    `/leaderboard`,
    fetcher,
  );

  return { leaderboard, isLoading };
};
