import { useLayoutEffect, useState } from 'react';
import { checkIsMobile } from '../utils/check-is-mobile';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const isMobile = checkIsMobile();
    setIsMobile(isMobile);
  }, []);

  return isMobile;
};
