import React from "react";
import { cn } from "../utils";

interface IProps {
    children?: React.ReactNode;
    classNames?: string;
  }

const Container: React.FC <IProps> = ({children, classNames}) => {
  return (
    <section
      className={cn(
        "w-full px-5 md:px-10 lg:px-10 xl:px-0 xl:max-w-7xl flex justify-center mx-auto",
        classNames
      )}
    >
      <div className="flex-1">
        {children}
      </div>
    </section>
  );
};

export default Container;
