import React from 'react';
import Button from '../Button';
import TelegramIcon from '../TelegramIcon';
import XIcon from '../XIcon';
import HeroImage from './HeroImage';

const Hero: React.FC = () => {
  return (
    <div className="relative">
      <HeroImage />

      <div className="space-y-4 lg:space-y-8">
        <p className="mx-auto max-w-[250px] text-center text-xl lg:max-w-none lg:text-3xl">
          <span className="font-bold">$TOL</span>
          <span className="px-px">–</span>
          <span>The Open League first community meme token</span>
        </p>

        <div className="flex items-center justify-center gap-2">
          <Button
            type="link"
            href="https://app.ston.fi/swap?chartVisible=false&ft=TON&tt=EQCOKRrOezeZfCgkKHO-PBnheexXjrFHZS-eRO5D_8h5YM5N"
          >
            GET $TOL
          </Button>

          <Button
            type="link"
            href="https://t.me/tolmeme"
            customclass="aspect-square lg:p-0"
          >
            <TelegramIcon fill="white" className="size-5" />
          </Button>

          <Button
            type="link"
            href="https://twitter.com/tolmeme"
            customclass="aspect-square lg:p-0"
          >
            <XIcon fill="white" customclass="size-5" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
