import React from "react";

interface IProps {
  children: React.ReactNode;
}

const Layout: React.FC<IProps> = ({ children }) => {
  return (
    <div className="relative bg-[#0A131F] text-gray-200 antialiased">
      {children}
    </div>
  );
};

export default Layout;
