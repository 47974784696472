import { useEffect, useState } from "react";
import type { FileWithPath } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { useWalletAddress } from "../../hooks/ton-connect";

type FileWithPathAndPreview = FileWithPath & {
  preview: string;
};

export const SelectAndUpload = ({
  onUpload,
}: {
  onUpload?: (file: File) => void;
}) => {
  const walletAddress = useWalletAddress();
  const [files, setFiles] = useState<FileWithPathAndPreview[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const removeFile = (file: FileWithPathAndPreview) => {
    setFiles((prev) => prev.filter((f) => f !== file));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const file = files[0];

    if (!file) {
      console.log("no file selected");
      return;
    }

    onUpload?.(file);
    setFiles([]);
  };

  return (
    <form onSubmit={handleSubmit}>
      {!files.length && (
        <section className="cursor-pointer select-none rounded-3xl shadow-lg md:border md:border-dashed">
          <div
            {...getRootProps({
              className:
                "md:h-32 py-3 px-6 md:w-full md:grid w-fit md:place-items-center md:px-16 bg-main-blue-700 md:bg-transparent rounded-full",
            })}
          >
            <input {...getInputProps()} />
            <p className="text-lg font-medium md:hidden">
              Select meme from device
            </p>
            <div className="hidden md:flex md:flex-col md:items-center md:gap-1">
              <p className="text-3xl">Drag'n'drop your meme here</p>
              <p className="text-lg text-gray-400">
                or click to select it from device
              </p>
            </div>
          </div>
        </section>
      )}
      {files.length > 0 && (
        <div>
          {files.map((file) => (
            <div key={file.name}>
              <div className="flex flex-col items-center justify-center gap-4">
                <img
                  className="max-h-60 object-scale-down md:max-h-52 md:max-w-[280px]"
                  src={file.preview}
                  onLoad={() => URL.revokeObjectURL(file.preview)}
                />
                <div className="flex items-center gap-2 text-lg *:w-[100px] md:*:w-[150px]">
                  <button
                    type="button"
                    onClick={() => removeFile(file)}
                    className="rounded-full bg-rose-400/40 py-1 text-rose-400 shadow-sm hover:bg-rose-400/80 hover:text-white"
                  >
                    Reset
                  </button>

                  <button
                    type="submit"
                    className="rounded-full bg-blue-400/40 py-1 text-blue-400 shadow-sm hover:bg-blue-400/80 hover:text-white"
                  >
                    Send it!
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </form>
  );
};
