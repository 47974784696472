import Layout from '../../layouts/default';
import Container from '../../layouts/Container';
import Header from '../../components/Header';
import Button from '../../components/Button';
import Hero from '../../components/Hero/Hero';
import About from '../../components/About';
import Footer from '../../components/Footer';
import { Link } from '../../components/Link';

export function HomePage() {
  return (
    <Layout>
      <Container>
        <Header>
          <Link to="/meme2earn">Meme2Earn</Link>
          <Link to="/memetinder">MemeTinder</Link>
        </Header>
      </Container>

      <div className="flex min-h-[calc(100vh-20px)] -translate-y-20 items-center justify-center">
        <Hero />
      </div>

      <Container classNames="mx-auto max-w-3xl lg:max-w-7xl lg:pb-20">
        <About />
      </Container>

      <Container classNames="mx-auto max-w-3xl pb-10 pt-20 lg:max-w-7xl lg:pt-32">
        {/* <Memes /> */}
      </Container>

      <Container>
        <Footer />
      </Container>
    </Layout>
  );
}
