import { $walletAddress } from '../hooks/ton-connect';

export const API_URL = process.env.REACT_APP_API_URL;

const CLAIM_DELAY = process.env.PUBLIC_CLAIM_DELAY_MS;

console.log('api url', API_URL);

export const getSecondsLeft = <T extends number | undefined>(
  uploadedAt?: T
) => {
  if (!uploadedAt) {
    return undefined as T;
  }

  const now = Date.now();
  const claimDelay = Number(CLAIM_DELAY);

  const msLeft = uploadedAt - now + claimDelay;
  const secondsLeft = msLeft / 1000;
  return secondsLeft as T;
};

export const fetcher = async (url: string) => {
  const walletAddress = $walletAddress.get();

  if (!walletAddress) {
    throw new Error('no_wallet_address');
  }

  const res = await fetch(API_URL + url, {
    headers: {
      'x-wallet-address': walletAddress,
    },
  });

  const data = await res.json();

  if (!res.ok) {
    throw new Error(data.message);
  }

  return data;
};

export const claim = async () => {
  const walletAddress = $walletAddress.get();

  if (!walletAddress) {
    throw new Error('no_wallet_address');
  }

  const res = await fetch(API_URL + '/claim', {
    headers: {
      'x-wallet-address': walletAddress,
    },
  });

  const data = await res.json();
  return data;
};

export const uploadFile = async (file: File) => {
  const walletAddress = $walletAddress.get();

  if (!walletAddress) {
    throw new Error('no_wallet_address');
  }

  const formData = new FormData();
  formData.append('image', file);

  const response = await fetch(process.env.REACT_APP_API_URL + '/newMeme', {
    method: 'POST',
    body: formData,
    headers: {
      'x-wallet-address': walletAddress,
    },
  });

  const data = await response.json();

  return {
    uploadedAt: data.uploadedAt,
    messageId: data.messageId,
  };
};

export const reset = async () => {
  const walletAddress = $walletAddress.get();

  if (!walletAddress) {
    throw new Error('no_wallet_address');
  }

  const res = await fetch(API_URL + '/reset', {
    method: 'POST',
    headers: {
      'x-wallet-address': walletAddress,
    },
  });

  const data = await res.json();
  return data;
};
