import React from "react";

const About = () => {
  const bullets = [
    "$TOL is The Open League first community meme token. TOL aims to allow more people to enjoy Telegram and the TON ecosystem. Experience the TON ecosystem on Telegram!",
    "$TOL is Telegram's first community meme token, connecting Telegram's 900 million users in a variety of ways to bring more people into the TON ecosystem and earn rewards in The Open League competitions!",
    "$TOL shares some ideas for connecting people. These ideas don't promise a roadmap, but you can see the spirit of revitalizing the TON ecosystem.",
  ];
  return (
    <div className="flex flex-col items-center justify-center gap-12 lg:flex-row lg:gap-20">
      <img src="/about.png" className="w-[500px] object-scale-down" />
      <div className="flex flex-col gap-3 lg:gap-5">
        <h2 className="text-2xl font-bold lg:text-4xl">
          What is The Open League{" "}
          <span className="px-1 highlight highlight-[#047CFA]">$TOL</span>?
        </h2>
        <ul className="flex flex-col gap-3 text-lg *:leading-[1.625rem] lg:gap-4 lg:text-xl lg:*:max-w-lg">
          {bullets.map((bullet, idx) => (
            <li key={idx}>{bullet}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default About;
